import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, DOCUMENT } from '@angular/core';
import { CheckForUpdateService } from '@scpc/modules/common/services/check-for-update.service';
import { AuthenticationService, RouterService } from '@scpc/modules/common/services';
import { ScpTourService } from '@scpc/modules/common/services/scp.tour.service';

import { RouterOutlet } from '@angular/router';
import { TourTippyModule } from '@scpc/modules/common/tour/tippy-tour.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'scp-app',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, TourTippyModule],
})
export class AppComponent {

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(update: CheckForUpdateService,
              routerService: RouterService,
              private readonly tourService: ScpTourService,
              private readonly authenticationService: AuthenticationService,
              @Inject(DOCUMENT) private readonly document: Document) {
    this.authenticationService.isAuthorized()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(authorized => this.updateAuthorization(authorized));
    this.authenticationService.authorization
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(authorized => this.updateAuthorization(authorized));
  }

  private updateAuthorization(authorized: boolean) {
    if (authorized) {
      this.document.body.classList.add('authorized');
    } else {
      this.document.body.classList.remove('authorized');
    }
  }

}
