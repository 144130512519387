<div class="scp-header-update">
  {{ 'We have been working some magic behind the scenes. The new update is now available.' | translate }}
  <div class="scp-header-update-actions">
    <scp-btn
      id="accept"
      type="1"
      [width]="80"
      [height]="30"
      [hasMarginTop]="false"
      text="Reload"
      (click)="reload()"
    ></scp-btn>
  </div>
</div>
